import React from "react"
import { Navbar } from "react-bootstrap"
import { Link } from "gatsby"
import logo from "../../images/logo.png"

import "./styles.scss"

class Navi extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            collapsed: true
        }
    }

    onToggle = () => this.setState((prevState => ({
        collapsed: !prevState.collapsed
    })))

    render() {
        const { location } = this.props
        return (
            <Navbar onToggle={this.onToggle} expand="md">
                <a className="navbar-brand" href="/">
                    <img className="navbar-image" src={logo} alt="ML Wray" />
                </a>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <ul className="nav main-nav nav-pills">
                        <li className="nav-item">
                            <Link to="/" className={location.pathname === '/' ? 'nav-link active' : 'nav-link'}>
                                Home
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/workshop" className={location.pathname === '/workshop' ? 'nav-link active' : 'nav-link'}>
                                Workshop
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/towing" className={location.pathname === '/towing' ? 'nav-link active' : 'nav-link'}>
                                Towing
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/about" className={location.pathname === '/about' ? 'nav-link active' : 'nav-link'}>
                                About
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/contact" className={location.pathname === '/contact' ? 'nav-link active' : 'nav-link'}>
                                Contact
                            </Link>
                        </li>
                    </ul>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

export default Navi;