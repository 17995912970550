import React from "react"
import Navi from "../Navi"
import Icon from "../Icon"
import { Link } from "gatsby"
import logo from "../../images/logo.png"

import "./styles.scss"
import ChatBot from "../ChatBot"

class Layout extends React.Component {
  render() {
    const { children, location } = this.props
    return (
      <>
        <section className="container top-desktop">
          <div className="row">
            <div className="col-md-9">
              <img className="logo" src={logo} alt="ML Wray" />
              <Navi location={location} />
            </div>
            <div className="col-md-3 contact-details">
              <div className="row">
                <div className="col-sm-2 my-auto">
                  <Icon name="location-arrow" />
                </div>
                <div className="col-sm-10 my-auto">
                  <p>
                    Unit 2/55 Albatross Road <br /> Nowra NSW 2541
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-2">
                  <Icon name="phone" />
                </div>
                <div className="col-sm-10">
                  <p>02 4422 0454</p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-2">
                  <Icon name="truck-pickup" />
                </div>
                <div className="col-sm-10">
                  <p>0412 528 293</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container top-mobile">
          <div className="row">
            <div className="col">
              <a
                className="btn btn-primary btn-sm"
                role="button"
                href="https://www.google.com/maps/place/M.L.+Wray+Automotive+Repairs+and+Towing/@-34.8905719,150.5926124,17z/data=!3m1!4b1!4m5!3m4!1s0x6b14806da0e052eb:0x72bac413e4bcb365!8m2!3d-34.8905763!4d150.5948064"
              >
                Get Directions <Icon name="location-arrow" />
              </a>
            </div>
            <div className="col text-right">
              <a
                className="btn btn-primary btn-sm"
                role="button"
                href="tel:0412528293"
              >
                24/7 Towing <Icon name="phone" />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Navi location={location} />
            </div>
          </div>
        </section>
        <section className="row header"></section>
        <section className="container">{children}</section>
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-md-6 footer-left">
                <p>M.L Wray Automotive 2024</p>
              </div>
              <div className="col-md-6 footer-right">
                <ul className="nav footer-nav justify-content-center">
                  <li className="nav-item">
                    <Link to="/privacy-policy" className="nav-link">
                      Privacy Policy
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/terms-of-use" className="nav-link">
                      Terms of Use
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row tag">
              <div className="col-12"></div>
            </div>
          </div>
        </footer>
        <ChatBot />
      </>
    )
  }
}

export default Layout
