import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import {
    faPhone,
    faLocationArrow,
    faArrowRight,
    faChevronRight,
    faSpinner,
    faTruckPickup
} from '@fortawesome/free-solid-svg-icons'

library.add(
    faPhone,
    faLocationArrow,
    faArrowRight,
    faChevronRight,
    faSpinner,
    faTruckPickup
)

const Icon = ({ name, spin }) => {
    if(spin === "true") {
        return (
            <FontAwesomeIcon icon={['fas', name]} spin />
        )
    } else {
        return (
            <FontAwesomeIcon icon={['fas', name]} />
        )
    }
}

export default Icon