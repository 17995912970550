import React, { Component } from 'react'
import Helmet from "react-helmet"

class ChatBot extends Component {
    render() {
        return (
            <Helmet>
                <script defer src="https://connect.podium.com/widget.js#ORG_TOKEN=731bded7-e209-4145-adc4-891878c904e4" id="podium-widget" data-organization-api-token="731bded7-e209-4145-adc4-891878c904e4"></script>
            </Helmet>
        )
    }
}

export default ChatBot